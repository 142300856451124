import ToastServiceApp from './ToastService.ToastServiceApp.vue';
import SingletonApp from './SingletonApp.ts';
import { reactive } from 'vue';

export default class ToastService {
    static #isInternalConstructing: boolean;
    private static _service: ToastService;

    private _singletonApp: SingletonApp
    private _initialized = false;

    state: ToastServiceState;

    static getToastService() {
        if (!ToastService._service) {
            ToastService.#isInternalConstructing = true;
            ToastService._service = new ToastService();
            ToastService._service.initialize();
            ToastService.#isInternalConstructing = false;
        }
        return ToastService._service;
    }

    constructor() {
        if (!ToastService.#isInternalConstructing) {
            throw new TypeError('ToastService is not constructable, use ToastService.getToastService()');
        }
        this.state = reactive(new ToastServiceState());
        this._singletonApp = SingletonApp.getOrCreateApp({
            id: 'o365-toast-service-app',
            appComponent: ToastServiceApp,
            provides: {
                'ToastService': ToastService,
            },
        });
    }

    private initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        this._singletonApp.mount();
    }

    /**
     * Add and show a toast with the default Toast component
     */
    addToast(toast: Toast, pToastOptions?: IToastOptions) {
        return this.state.push(toast, pToastOptions);
    }

    /**
     * Add and show a toast with custom Toast component
     */
    addCustomToast(customToast: CustomToast, options?: IToastOptions) {
        return this.state.pushCustom(customToast, options);
    }
}

class ToastServiceState {
    /** Toasts seperated into containers */
    containers: Record<string, ToastState[]> = {};
    /** Mappings for toasts uids to containers */
    private _toastsIndex: Record<string, string> = {};

    /** Render global toast backrop, used by confirm dialogs */
    backdrop = false;

    toasts: ToastState[] = [];

    constructor() { }

    push(toast: Toast, options: IToastOptions = {}) {
        const toastUID = window.crypto['randomUUID']();
        if (options.container == null) { options.container = 'o365-toasts-left-top'; }
        const foundToast = this.containers[options.container]?.find(x => x.value.category === toast.category && x.value.message === toast.message)
        if (foundToast) {
            foundToast.instance += 1
            return
        }
        this._initContainer(options.container);
        this.containers[options.container].push({ uid: toastUID, type: 'base', value: toast, options: options, instance: 1 });
        this._toastsIndex[toastUID] = options.container;

        // this.toasts.push({ uid: toastUID, type: 'base', value: toast });
        return toastUID;
    }

    pushCustom(customToast: CustomToast, options: IToastOptions = {}) {
        const toastUID = window.crypto['randomUUID']();

        if (options.container == null) { options.container = 'o365-toasts-left-top'; }
        /* const foundToast = this.containers[options.container]?.find(x => x.value.category === customToast.category && findCustomToast(x.value.props, customToast.props))
        if (foundToast) {
            foundToast.instance += 1
            return
        } */
        this._initContainer(options.container);
        this.containers[options.container].push({ uid: toastUID, type: 'custom', value: customToast, options: options, instance: 1 });
        this._toastsIndex[toastUID] = options.container;

        // this.toasts.push({ uid: toastUID, type: 'custom', value: customToast });
        return toastUID;
    }

    remove(uid: string) {
        const key = this._toastsIndex[uid];
        const index = this.containers[key]?.findIndex(x => x.uid === uid);
        if (index !== -1) {
            // if (typeof this.containers[key][index].options?.onRemove === 'function') {
            //     this.containers[key][index].options.onRemove();
            // }
            this.containers[key].splice(index, 1);
            // this.toasts.splice(index, 1);
            delete this._toastsIndex[uid];
            this._unmountContainer(key);
        }
    }

    find(uid: string) {
        const key = this._toastsIndex[uid];
        return this.containers[key]?.find(x => x.uid === uid);
    }

    private _initContainer(key: string) {
        if (this.containers[key] == null) { this.containers[key] = []; }
    }

    private _unmountContainer(key: string) {
        if (this.containers[key] != null && this.containers[key].length === 0) {
            delete this.containers[key];
        }
    }
}

export function getToastTypeFromNumber(num : number) : ToastType {
    switch (num) {
        case 1: return ToastType.Danger;
        case 2: return ToastType.Warning;
        case 3: return ToastType.Info;
        default: return ToastType.Danger;
    }
}

export enum ToastType {
    Danger = 'danger',
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
}

export interface Toast {
    title?: string;
    message?: string;
    iconClass?: string;
    type: ToastType;
    category: ToastType;
    backdrop?: boolean,
    toastOptions: {
        autohide: boolean,
        delay: number,
        slimVersion: boolean
    };
}

export interface CustomToast {
    component: any,
    props: any,
    toastClass?: any,
    category: 'custom',
    backdrop?: boolean,
    toastOptions: {
        autohide: boolean,
        delay: number
    };
}

export interface IToastOptions {
    container?: string;
    onRemove?: Function;
    noProgressBar?: boolean;
}

interface ToastState {
    uid: string;
    type: 'base' | 'custom';
    value: Toast | CustomToast;
    options: IToastOptions;
    instance: number;
}

function findCustomToast(props: any, cProps: any) {
    for (const prop in props) {
        if (cProps.hasOwnProperty(prop)) {
            if (cProps[prop] !== props[prop]) {
                return false
            }
        }
    }
    for (const cProp in cProps) {
        if (props.hasOwnProperty(cProp)) {
            if (props[cProp] !== cProps[cProp]) {
                return false
            }
        }
    }
    return true
}